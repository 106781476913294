.promo-banner::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(90deg, #000 1%, rgba(1, 0, 18, 0) 8%, rgba(0, 0, 1, 0) 92%, #000 99%);
	z-index: 1;
	pointer-events: none;
}

.promo-banner .swiper-wrapper {
	white-space: nowrap;
}

.promo-banner .promo-banner-slide {
	width: 320px;
	height: 96px;
}

.promo-banner .promo-banner-slide img {
	height: 96px;
	width: 96px;
	object-fit: cover;
}

.promo-banner .promo-banner-slide .promo-banner-text {
	padding: 10px 16px;
	width: calc(100% - 136px);
}

.promo-banner .swiper-container {
	padding: 28px;
}

.promo-banner .promo-banner-text {
	white-space: normal;
}

.promo-banner .promo-banner-arrow {
	width: 40px;
}

.promo-banner .promo-banner-arrow {
	width: 40px;
}

.promo-banner .promo-banner-arrow img {
	width: 24px;
	height: auto;
}
