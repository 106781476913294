.tracklink {
	margin: 0 15px;
	min-width: 24px;
	min-height: 24px;

	@media (min-width: theme("screens.md")) {
		margin: 0 7px;
		font-weight: 400;
	}

	span {
		margin-left: 10px;

		&::after {
			content: "";
			display: block;
			background: theme("colors.greyLine");
			height: 1px;
			z-index: 5;
			margin-right: -20px;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;

			@media (min-width: theme("screens.md")) {
				height: 0;
				width: 0;
				display: none;
			}
		}
	}

	.platform-logo {
		width: 24px;
		height: 24px;
	}

	.open-external {
		width: 24px;
		height: 24px;
		position: absolute;
		right: 0;
	}
}
