.track-container {
	width: 100%;

	@media (min-width: theme("screens.md")) {
		width: calc(100% - 165px);
		padding-right: 30px;
	}
}

.show-tracklist {
	width: 165px;
}

.header-tracklist-container {
	padding: 0 25px;
	height: 115px;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.12), inset 0 -1px 0 rgba(255, 255, 255, 0.12);
	bottom: 0;
	z-index: 50;

	@media (min-width: theme("screens.md")) {
		padding: 0 30px;
		height: 65px;
		bottom: auto;
	}

	&.hide {
		display: none;
	}

	&.show {
		display: flex;
	}

	.show-tracklist {
		a {
			&:focus {
				outline: none;
				box-shadow: none;
			}
		}
	}
}
