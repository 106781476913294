/* no tracks */
/* .no-track-wrapper {
	display: flex;
	align-items: center;
	padding: 15px 25px;
	color: #9b9b9b;
	overflow: hidden;
}
.no-track-wrapper .no-track-info {
	font-style: normal;
	line-height: 24px;
}

.no-track-wrapper .no-track-info .loading {
	margin: 0;
}

.no-track-wrapper .no-track-info .loading::after {
	content: " .";
	animation: dots 1.5s steps(5, end) infinite;
}

@keyframes dots {
	0%,
	20% {
		color: rgba(0, 0, 0, 0);
		text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
	}
	40% {
		color: #9b9b9b;
		text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
	}
	60% {
		text-shadow: 0.25em 0 0 #9b9b9b, 0.5em 0 0 rgba(0, 0, 0, 0);
	}
	80%,
	100% {
		color: #9b9b9b;
		text-shadow: 0.25em 0 0 #9b9b9b, 0.5em 0 0 #9b9b9b;
	}
} */

/* no tracks detected */

.no-track-container .no-track {
	padding: 19px 25px;
}

.header-track-wrapper .no-track-container .no-track {
	padding: 0;
}

.no-track-container .no-track p::before {
	content: "?";
	height: 32px;
	width: 32px;
	background: theme("colors.primary");
	display: inline-flex;
	color: theme("colors.dark");
	border-radius: 6px;
	margin-right: 15px;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	font-family: "Work Sans", sans-serif;
	font-weight: 500;
}
