.header-current-artist-container {
	margin-top: 70px;
	height: 80px;
	padding: 0 25px;
	box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.12);

	@media (min-width: theme("screens.md")) {
		margin-top: 0;
		height: auto;
		padding: 0;
		box-shadow: none;
	}

	.artist-image {
		height: 48px;
		width: 48px;
		margin-right: 15px;
	}

	.live::after {
		content: "";
		display: block;
		width: 8px;
		height: 8px;
		background: theme("colors.primary");
		border-radius: 100%;
		margin-left: 8px;
	}
}
