.header-more-button {
	.dropdown-header-icon {
		height: 40px;
		width: 40px;
		cursor: pointer;

		img {
			width: 24px;
			height: 24px;
		}
	}

	.close-button {
		height: 30px;
		width: 30px;

		&:focus {
			outline: none;
		}

		img {
			width: 24px;
			height: 24px;
		}
	}

	.dropdown-header-content {
		display: none;
		padding-bottom: 35px;
		box-shadow: 0 12px 40px rgba(0, 0, 0, 0.96);
		background: theme("colors.greyUltraDark");
		top: auto;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 5;
		position: fixed;
		z-index: 60;

		@media (min-width: theme("screens.md")) {
			padding: 13px;
			position: absolute;
			top: 15px;
			right: 6px;
			bottom: auto;
			left: auto;
			z-index: 1;
			min-width: 310px;
		}

		&.open {
			display: block;
		}

		.tracklink {
			padding: 0;
			height: 60px;
			align-items: center;

			@media (min-width: theme("screens.md")) {
				padding: 6px 0;
				height: auto;
			}
		}

		.dropdown-header {
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}

		> a {
			padding: 0;
			height: 60px;
			margin: 0 16px;

			@media (min-width: theme("screens.md")) {
				padding: 0;
				height: 45px;
				margin: 0;
			}

			span {
				margin-left: 10px;
			}
		}
	}

	.share {
		.dropdown-header-container.open {
			background: transparent;
		}
	}

	.dropdown-header-container {
		&.open {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.8);
			z-index: 50;

			@media (min-width: theme("screens.md")) {
				position: relative;
				background: transparent;
			}
		}
	}

	.copy-link {
		@media (min-width: theme("screens.md")) {
			height: 45px;
		}

		span {
			margin-left: 10px;
		}

		img {
			width: 24px;
			height: 24px;
		}
	}

	.copy-link-mobile {
		span {
			&::after {
				content: "";
				display: block;
				background: theme("colors.greyLine");
				height: 1px;
				z-index: 5;
				margin-right: -20px;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
			}
		}
	}
}
