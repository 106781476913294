.lineup-container {
	white-space: nowrap;
	padding-bottom: 3.5rem;
}

.lineup-container::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(90deg, rgba(0, 0, 0, 1) 1%, rgba(1, 0, 18, 0) 8%, rgba(0, 0, 1, 0) 92%, rgba(0, 0, 0, 1) 99%);
	z-index: 1;
	pointer-events: none;
}

.lineup-wrapper {
	padding: 0 25px;
}

.title-container {
	padding: 15px 25px;
}

.swiper-container {
	overflow: visible !important;
}

.swiper-slide {
	cursor: grab;
	display: inline-flex;
}

.swiper-slide.selected .box {
	background-color: theme("colors.primary");
	color: theme("colors.dark");
}

.swiper-slide.selected .box .time,
.swiper-slide.selected .box .artist {
	color: theme("colors.dark");
}

.swiper-slide.current .box .now-live::after {
	content: "";
	display: block;
	position: absolute;
	width: 8px;
	height: 8px;
	top: 0;
	bottom: 0;
	right: -18px;
	margin: auto;
	background-color: theme("colors.primary");
	border-radius: 50px;
}

.swiper-slide.current.selected .box .now-live::after {
	content: "";
	display: block;
	position: absolute;
	width: 8px;
	height: 8px;
	top: 0;
	bottom: 0;
	right: -18px;
	margin: auto;
	background-color: theme("colors.dark");
	border-radius: 50px;
}

.box p {
	margin: 0;
}

.box .time {
	margin-right: 5px;
}

.box img {
	width: 32px;
	height: 32px;
	margin: 0 10px;
}

.current .box img,
.current.selected .box img {
	margin-left: 30px;
}

.box .artist {
	margin-left: 5px;
}

.w-auto {
	width: auto !important;
}
