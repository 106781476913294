.share {
	.dropdown-header {
		img {
			width: 24px;
			height: 24px;
		}

		span {
			margin-left: 10px;
		}
	}

	.dropdown-header-link {
		padding: 0;
		height: 60px;
		margin: 0 16px;

		img {
			width: 24px;
			height: 24px;
		}
	}

	.span-underline {
		margin-left: 10px;

		&::after {
			content: "";
			display: block;
			background: theme("colors.greyLine");
			height: 1px;
			z-index: 5;
			margin-right: -20px;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}

	.share-link {
		img {
			width: 24px;
			height: 24px;
		}

		span {
			margin-left: 10px;
		}
	}

	.close-button {
		height: 30px;
		width: 30px;

		&:focus {
			outline: none;
		}

		img {
			width: 24px;
			height: 24px;
		}
	}
}
