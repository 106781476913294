.header-artist-wrapper {
	.header-react {
		z-index: 60;

		.header-artist-container {
			padding-left: 30px;
			height: 90px;
			box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.12), inset 0 -1px 0 rgba(255, 255, 255, 0.12);
		}
	}

	.header-react-mobile {
		.header-artist-container {
			height: auto;

			.fixed-container {
				z-index: 50;
				box-shadow: inset 0 1px 0 rgb(255 255 255 / 12%), inset 0 -1px 0 rgb(255 255 255 / 12%);

				&.z-index {
					z-index: 60;
				}
			}
		}
	}
}
