.header-logo-claim {
	height: 70px;

	@media (min-width: theme("screens.md")) {
		height: 90px;
	}

	.logo-claim-container {
		width: 330px;

		@media (min-width: theme("screens.md")) {
			width: 100%;
		}
	}

	.header-logo-claim {
		height: 70px;
		width: 100%;
		z-index: 50;

		@media (min-width: theme("screens.md")) {
			box-shadow: inset 0 1px 0 rgb(255 255 255 / 12%), inset 0 -1px 0 rgb(255 255 255 / 12%);
			height: 90px;
			width: auto;
			box-shadow: none;
			z-index: 1;
		}
	}

	.round-container {
		height: 330px;
		width: 330px;
		right: -30px;
	}

	.logo-claim-container {
		margin-left: 15px;

		img {
			margin-right: 15px;
		}
	}
}
