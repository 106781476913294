.open-playlist {
	img {
		width: 24px;
		height: 24px;
	}

	.open-external {
		position: absolute;
		right: 0;
	}

	.span-container {
		margin-left: 10px !important;

		&::after {
			content: "";
			display: block;
			background: theme("colors.greyLine");
			height: 1px;
			z-index: 5;
			margin-right: -20px;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;

			@media (min-width: theme("screens.md")) {
				display: none;
			}
		}

		span {
			margin: 0 !important;
		}
	}
}
