.track-row {
	.track-info-container {
		padding: 19px 25px;
	}

	.beatport-container {
		padding: 15px 12px 10px 20px;

		&.show {
			display: block;
		}

		&.hide {
			display: none;
		}
	}

	.image-container {
		margin-right: 15px;
		height: auto;
		width: 64px;

		@media (min-width: theme("screens.md")) {
			height: 39px;
			width: 39px;
		}

		&.beatport {
			cursor: pointer;

			.icon {
				position: absolute;
				z-index: 3;
				height: 35px;
				width: 35px;

				@media (min-width: theme("screens.md")) {
					height: 24px;
					width: 24px;
				}
			}
		}

		&.no-beatport {
			pointer-events: none;
		}

		.cover {
			z-index: 2;
			width: 64px;

			@media (min-width: theme("screens.md")) {
				width: 32px;
				height: 32px;
			}
		}

		&.close {
			border-radius: 6px;
			transition: border-radius 0.3s ease;

			.cover {
				border-radius: 6px;
				transition: border-radius 0.3s ease;
			}
		}

		&.open {
			border-radius: 99px;
			transition: border-radius 0.3s ease;

			.cover {
				border-radius: 99px;
				transition: border-radius 0.3s ease;
			}
		}
	}

	.title {
		span {
			margin-left: 5px;
		}
	}

	.scrolling-container {
		width: 85%;
	}

	.text-container {
		position: relative;
		overflow: hidden;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background: linear-gradient(90deg, rgba(2, 0, 36, 0) 60%, rgba(0, 0, 0, 1) 99%);

			@media (min-width: theme("screens.md")) {
				background: linear-gradient(90deg, rgba(2, 0, 36, 0) 60%, rgba(0, 0, 0, 1) 90%);
			}
		}

		p {
			white-space: nowrap;
			margin: 0;
		}
	}

	.text-container.touch .scrolling-container .title.animate,
	.text-container.touch .scrolling-container .artist.animate,
	.text-container.touch .scrolling-container .label.animate,
	.text-container:hover .scrolling-container .title.animate,
	.text-container:hover .scrolling-container .artist.animate,
	.text-container:hover .scrolling-container .label.animate {
		animation: marquee 12s linear infinite;
	}

	.text-container-name-artist {
		width: calc(100% - 93px);
		overflow: hidden;
		/* 93px = Image Size + Margin + Dropdown Icon Size */
		@media (min-width: theme("screens.md")) {
			width: calc(60% - 20px);
		}
	}

	.text-container-name {
		width: 100%;

		@media (min-width: theme("screens.md")) {
			width: calc(50% - 40px);
		}
	}

	.text-container.text-container-artist {
		width: 100%;

		@media (min-width: theme("screens.md")) {
			width: 50%;
		}
	}

	.text-container-label {
		width: 20%;
	}

	.url-container {
		a {
			margin: 0 16px;

			@media (min-width: theme("screens.md")) {
				margin: 0 7px;
			}
		}
	}
}

/* Dropdown */
.dropdown-icon {
	min-width: 24px;
	min-height: 24px;
	margin: 0 7px;

	img {
		width: 24px;
		height: 24px;
	}
}

.dropdown-container.open {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.8);
	z-index: 60;
}

.dropdown-content {
	display: none;
	padding-bottom: 35px;
	box-shadow: 0 12px 40px rgba(0, 0, 0, 0.96);
	background: theme("colors.greyUltraDark");
	top: auto;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 5;

	@media (min-width: theme("screens.md")) {
		padding: 13px;
		position: absolute;
		top: 35px;
		right: 6px;
		bottom: auto;
		left: auto;
		z-index: 1;
		min-width: 200px;
	}

	img {
		width: 24px;
		height: 24px;
	}

	&.open {
		display: block;
	}

	.tracklink {
		padding: 0;
		height: 60px;
		align-items: center;

		@media (min-width: theme("screens.md")) {
			padding: 6px 0;
			height: auto;
		}
	}

	.dropdown-header {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;

		img {
			margin-right: 10px;
		}

		button {
			height: 30px;
			width: 30px;

			&:focus {
				outline: none;
			}

			// &::before {
			// 	content: "";
			// 	// background-image: url("../Static/Images/close.png");
			// 	background-position: center;
			// 	background-size: contain;
			// 	display: block;
			// 	width: 24px;
			// 	height: 24px;
			// }
		}
	}
}

.dropdown-content-mobile {
	position: fixed;
	z-index: 60;
}

/* Current track row */
.track-row.current {
	.track-info-container {
		background: rgba(255, 235, 0, 0.24);
		box-shadow: inset 0 -3px 0 theme("colors.primary");

		&::after {
			background: none;
		}
	}

	.text-container::after {
		display: none;
	}

	.title,
	.artist,
	.label {
		color: theme("colors.primary");
	}

	.image-container {
		border: 1px solid theme("colors.primary");
	}
}

/* Animations */
@keyframes marquee {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-100%);
	}
}
