.table-head {
	padding: 15px 25px;
}

.table-head .artist {
	display: none;
}

.table-head .label {
	display: none;
}

@media (min-width: theme("screens.md")) {
	.table-head .track {
		width: 30%;
	}

	.table-head .artist {
		width: 30%;
		display: block;
	}

	.table-head .label {
		width: 20%;
		display: block;
	}
}
