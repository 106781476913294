.loading-indicator {
	background-color: #000;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	justify-content: center;
	align-items: center;
}

.indicator {
	display: inline-block;
	width: 80px;
	height: 80px;
}

.indicator::after {
	content: " ";
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid theme("colors.primary");
	border-color: #ffeb00 transparent;
	animation: dual-ring-animation 1.2s linear infinite;
}

@keyframes dual-ring-animation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
