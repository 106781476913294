.live-video-container.show {
	display: block;
}

.live-video-container.hide {
	display: none;
}

.data {
	padding: 25px;
}

.data .now-live::after {
	content: "";
	background: theme("colors.primary");
	height: 6px;
	width: 6px;
	display: block;
	border-radius: 100%;
	margin-left: 8px;
}

.not-live-info {
	padding: 25px;
}

.now-live.show {
	display: flex;
}

.now-live.hide {
	display: none;
}

.button-container {
	padding: 15px 25px;
}

.button-container button:focus {
	outline: none;
}

.button-container button img {
	margin-left: 10px;
	width: 24px;
}

.video-container {
	border-radius: 5px;
	transition: height 0.6s ease-in-out;
	height: auto;
	margin: 0;
}

.video-container.hide {
	height: 0;
	display: none;
}

.event-date {
	margin-left: 7px;
}
