.playlist-container {
	border-radius: 100px;
	padding: 13px 25px;
	margin-bottom: 15px;
}

.playlist-container button {
	width: 24px;
	height: 24px;
	margin-left: 12px;
}

.playlist-container button img {
	width: 24px;
	height: 24px;
}

.playlist-container button:focus {
	outline: none;
}
