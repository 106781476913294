
@font-face {
	font-family: "silka-bold";
	font-display: swap;
	src: url("/src/fonts/silka-bold-webfont.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "silka-regular";
	font-display: swap;
	src: url("/src/fonts/silka-regular-webfont.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "silka-lightitalic";
	font-display: swap;
	src: url("/src/fonts/silka-lightitalic-webfont.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}


