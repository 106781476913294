.header-track-wrapper {
	.image-container {
		width: 60px;
		height: 60px;

		@media (min-width: theme("screens.md")) {
			width: 40px;
			height: 40px;
		}
	}

	.cover {
		z-index: 2;
		position: relative;
		max-width: 55px;

		@media (min-width: theme("screens.md")) {
			max-width: 32px;
		}
	}

	.current-track {
		width: calc(100% - 60px);

		.header-track-wrapper .current-track {
			width: 100%;
		}
	}

	.track-display.touch .current-track.animate,
	.track-display.touch .current-track .scrollable-track.animate,
	.scrollable-container.touch .scrollable-track.animate,
	.track-display:hover .current-track.animate,
	.track-display:hover .current-track .scrollable-track.animate,
	.scrollable-container:hover .scrollable-track.animate {
		animation: marquee 12s linear infinite;
	}

	.scrollable-container {
		width: 100%;

		@media (min-width: theme("screens.md")) {
			width: calc(100% - 135px);
		}
	}

	.now-playing::after {
		content: "";
		display: block;
		width: 8px;
		height: 8px;
		background: theme("colors.primary");
		border-radius: 100%;
		margin-left: 8px;
	}

	.scrollable-track {
		padding-left: 15px;

		@media (min-width: theme("screens.md")) {
			padding-left: 0;
		}

		p,
		span {
			white-space: nowrap;
		}
	}

	.title {
		margin-left: 5px;
	}

	.mix {
		margin-left: 5px;
		margin-right: 5px;
	}

	.track-display {
		width: calc(100% - 24px);

		@media (min-width: theme("screens.md")) {
			width: calc(100% - 155px);
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background: linear-gradient(90deg, rgba(2, 0, 36, 0) 75%, rgba(18, 18, 18, 1) 90%);

			@media (min-width: theme("screens.md")) {
				background: linear-gradient(90deg, rgba(2, 0, 36, 0) 90%, rgba(18, 18, 18, 1) 99%);
			}
		}

		.live-container {
			padding-left: 15px;
			width: 135px;
		}
	}

	.no-track-container {
		border: 0;
		height: 62px;
		display: flex;
		justify-content: center;

		.no-track {
			padding: 10px 25px;
		}
	}
}

@keyframes marquee {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-100%);
	}
}
